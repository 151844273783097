@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

#__next {
  width: 100%;
  height: 100%;
  min-height: 100%;
}
#__next > div {
  width: 100%;
  min-height: 100%;
}
body {
  font-family: "Poppins" !important;
}
/**
Input css
*/
.input-with-label-in-textbox {
  border: 1px solid #e0e0e3 !important;
  border-radius: 7px !important;
}

.input-with-label-in-textbox:focus, 
.input-with-label-in-textbox:focus-visible {
  outline: none;
  border: solid 1px #4e2c90 !important;
}

.input-with-label-in-textbox::placeholder {
  color: #E0E0E3;
}

.input-with-label-in-textbox .ant-input.ant-input-lg {
  font-size: 0.75rem !important;
}

.ant-collapse {
  background-color: #ffffff !important;
}

.created-date-range-wrapper .ant-picker {
  height: 0 !important;
  visibility: hidden;
}

a.ant-btn {
  padding: 7px 15px !important;
  line-height: 16px !important;
}
.cross-btn {
  background: #8892a5;
  color: #ffffff;
  height: 20px;
  width: 20px;
  line-height: 20px;
  display: block;
  text-align: center;
  border-radius: 20px;
}

.ant-input-lg {
  /* height: 35px !important; */
}

.custom-search .ant-input-affix-wrapper,
.custom-search .ant-input-affix-wrapper:hover,
.custom-search .ant-input-affix-wrapper:focus {
  border-right-width: 0px !important;
}

.custom-search input {
  font-size: 13px;
  font-weight: 500;
  color: #8892a5;
}

.ant-pagination-item-active {
  border-color: transparent !important;
  border-radius: 12px !important;
  background: #e9e9f8 !important;
}
.ant-select-selector input::placeholder,
.ant-select-selector .ant-select-selection-placeholder {
  color: #4e2c90 !important;
}
.ant-switch-checked {
  background-color: #83d49d !important;
}
.toggleActiveColorBlue .ant-switch-checked {
  background-color: #4E2C90 !important;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #8892a5;
  display: inline-block;
  margin-bottom: 3px;
}

/* pagination */
.custom-table2 .ant-pagination {
  display: flex;
  width: 100%;
  align-items: center;
}
.custom-table2 .ant-pagination .ant-pagination-total-text {
  flex: 1 1 auto;
}

/* dialog */
.custom-modal .ant-modal-content {
  border-radius: 12px;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
.custom-popover .ant-popover-arrow {
  border-color: #654e96 !important;
}
.custom-popover .ant-popover-inner {
  background-color: #654e96;
}
.custom-popover .ant-popover-inner-content {
  color: #fff;
  padding: 5px 10px;
  border-radius: 0.5rem !important;
}
.custom-tab .ant-tabs-tab-btn {
  opacity: 1;
  color: #8892a5;
  font-size: 13px;
  font-family: Poppins, sans-serif !important;
}
.custom-tab .ant-tabs-nav {
  margin-bottom: 30px !important;
}

.custom-tab .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #e0e0e3;
}

.custom-tab .ant-tabs.ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap {
  padding: 0 30px;
}
.custom-tab .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #383a65;
  font-weight: 600;
}
.custom-tab .ant-tabs-ink-bar {
  background: #383a65;
}
/* select styling ant */
.ant-select .ant-select-selector {
  border-radius: 0.5rem !important;
  background-color: #e9e9f8 !important;
  background-color: rgba(233, 233, 248, var(--bg-opacity)) !important;
}

.custom-placeholder .ant-select-selector {
  border-color: transparent !important;
  border-right-width: 0 !important;
}

.custom-placeholder .ant-select-selection-placeholder {
  /* font-family: "Poppins, sans-serif" !important; */
  font-weight: 500;
  color: #4e2c90;
  opacity: 1;
  font-size: 0.75rem;
}

.custom-placeholder input::placeholder {
  font-weight: 500;
  color: #4e2c90;
  opacity: 1;
  font-size: 0.75rem;
}

.ant-picker:hover,
.ant-picker:focused,
.custom-rangepicker .ant-picker:hover,
.ant-picker-focused {
  border-color: transparent !important;
  border-right-width: 0 !important;
}

.custom-rangepicker .ant-picker-input > input {
  font-weight: 500 !important;
  color: #4e2c90 !important;
}

.anticon svg {
  display: flex !important;
}

.custom-sort .ant-table-thead .ant-table-cell .ant-table-column-sorter-inner {
  display: none;
}

.custom-box {
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1) !important;
}

/* upload list */
.documents-list .ant-upload-list-picture-card-container,
.documents-list .ant-upload-select-picture-card {
  width: 18% !important;
  width: calc(20% - 16px) !important;
  height: 160px !important;
  background-color: #fafafa;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  border-radius: 0.5rem !important;
  margin: 0 16px 16px 0 !important;
}

.documents-list .ant-upload-list-item-file {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.documents-list .ant-upload-list-item-file .anticon {
  font-size: 64px !important;
}

.documents-list .ant-upload-list-item-actions .anticon {
  font-size: 24px !important;
  width: 24px !important;
}

.documents-list .ant-upload-list-item-info > span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.documents-list .ant-upload-list-item-info > span .ant-upload-list-item-file {
  flex: 0 0 64px;
}

.documents-list .ant-upload-list-item-info > span .ant-upload-list-item-file svg {
  width: 32px;
}

.documents-list .ant-upload-list-item-info > span .ant-upload-list-item-file svg path:first-child {
  fill: #fff;
}

.documents-list .ant-upload-list-item-info > span .ant-upload-list-item-file svg path:nth-child(2) {
  fill: #674f98;
}

.documents-list .ant-upload-list-item-info > span .ant-upload-list-item-name {
  position: static;
  flex: 0 0 auto;
  font-size: 0.9375rem !important;
  margin-top: 0;
  color: #674f98 !important;
  font-family: Poppins, sans-serif !important;
}

.documents-list .ant-upload.ant-upload-select-picture-card > .ant-upload {
  font-size: 0.9375rem !important;
  color: #674f98 !important;
  font-family: Poppins, sans-serif !important;
}

.documents-list .ant-upload-list-picture-card .ant-upload-list-item {
  border: 0px !important;
}

.custom-tooltip-scroll .ant-tooltip-inner::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar,
.flat-table .ant-table-body::-webkit-scrollbar,
.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
  width: 4px !important;
  border-radius: 10px;
}

.custom-tooltip-scroll .ant-tooltip-inner::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb,
.flat-table .ant-table-body::-webkit-scrollbar-thumb,
.rc-virtual-list-scrollbar-thumb {
  background: #e0e0e3 !important;
  border-radius: 10px !important;
  min-height: 44px !important;
}

.custom-scrollbar .ant-table-scroll-horizontal .ant-table-body::-webkit-scrollbar {
  height: 6px !important;
}

pre.custom-scrollbar::-webkit-scrollbar-thumb,
pre.custom-scrollbar::-webkit-scrollbar {
  height: 4px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.gm-style .gm-style-iw-c {
  max-width: 394px !important;
}

.gm-style-iw-c,
.gm-style-iw-d {
  /* background-color: #F3F5F8 !important; */
  overflow: hidden !important;
}
.gm-style-iw-d > div {
  overflow: auto;
  margin-right: 12px;
}
.gm-style-iw-t::after {
  background: linear-gradient(
    45deg,
    rgb(243 245 248) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

.gm-style-iw.gm-style-iw-c {
  height: auto !important;
  max-height: 260px !important;
}

.gm-style .gm-style-iw-d {
  max-height: none !important;
}
.gm-style-iw-d {
  max-height: none !important;
}

.gm-fullscreen-control {
  border-radius: 7px !important;
}
/* .gm-fullscreen-control img {
  content: url("/images/icons/expand-map.svg") !important;
} */

.gmnoprint div:first-child {
  border-radius: 20px !important;
  width: 30px !important;
  z-index: 45;
}
.gmnoprint div:first-child button {
  width: 30px !important;
}
/* .gm-control-active:first-child img {
  content: url("/images/icons/plus-blue.svg");
  width: 10px !important;
} */
/* .gm-control-active:last-child img {
  content: url("/images/icons/minus-blue.svg");
  width: 10px !important;
} */

.custom-card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  z-index: 0;
}

.custom-popover1 .ant-popover-inner-content {
  border-radius: 0.5rem !important;
}

.leaflet-marker-icon {
  border: none !important;
  background-color: transparent !important;
}

.leaflet-zone-name {
  background-color: transparent !important;
  border: none !important;
  color: #4e2c90 !important;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #e0e0e3;
  border-radius: 10px;
  min-height: 44px !important;
}
/* 
.custom-select-disabled .ant-select-dropdown .ant-select-item-option-disabled:not(.ant-select-item-option-selected) {
  background-color: #e0e0e3 !important;
  color: #383A65 !important;
} */
.body-color2 {
  color: #8892a5;
}
.theme-color-bg2 {
  background-color: #4e2c90;
}

/* flat table css */

.flat-table .ant-table-thead .ant-table-cell {
  background-color: #e8ebf0 !important;
  padding-top: 13px;
  padding-bottom: 14px;
  text-transform: uppercase;
  color: #8892a5;
  font-size: 12px;
  font-weight: 600;
}
.flat-table .ant-table-thead .ant-table-cell:nth-child(2),
.flat-table .ant-table-tbody .ant-table-cell:nth-child(2) {
  padding-left: 5px;
}
.flat-table table table .ant-table-thead .ant-table-cell {
  background-color: #fff !important;
}
.flat-table .ant-table-thead tr th:first-child,
.flat-table table tr td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.flat-table table tr td:first-child {
  padding: 8px 0 8px 10px;
}
.flat-table table .ant-table-thead > tr > th:first-child{
  padding-left: 15px;
}

.flat-table .ant-table-thead tr th:last-child,
.flat-table table tr td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.flat-table table {
  background-color: #f3f5f8;
  border-spacing: 0 4px !important;
}

.flat-table .ant-table-fixed-header .ant-table-header table {
  border-spacing: 0 !important;
}

.flat-table .ant-table-fixed-header {
  margin-bottom: 26px;
}

.flat-table .ant-table-content {
  background-color: #f3f5f8;
}

.flat-table .ant-table-content .ant-table-content {
  background-color: #ffff;
}

.flat-table table tr td,
.flat-table table tr th {
  padding: 10px 0px 10px 16px;
}
.row-with-image.flat-table table tr td {
  /* padding: 20px 0 20px 16px; */
}
.row-with-horizontal-scroll.flat-table .ant-table-ping-left .ant-table-cell-fix-left-first::after,
.row-with-horizontal-scroll.flat-table .ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: none;
}
.flat-table table tr td {
  background-color: white;
  font-size: 13px;
  color: #8892a5;
  font-weight: 500;
}
.flat-table table table .table-row-light td {
  background-color: #f3f5f8;
}
.flat-table table .ant-checkbox-inner {
  border-collapse: collapse !important;
  border: 1px solid #e0e0e3;
}
.ant-pagination-total-text {
  float: right !important;
}

.flat-table table .ant-table-thead > tr > th {
  border-bottom: unset;
}
.flat-table table .ant-table-wrapper::before {
  display: table;
  content: none !important;
}

.flat-table .ant-table-expanded-row table,
.flat-table tr.ant-table-expanded-row:hover > td {
  background-color: #ffffff;
}

.flat-table .ant-table-cell::before {
  display: none;
}

.flat-table .ant-table-thead .ant-table-cell .ant-table-column-sorter-inner {
  display: none;
}

.flat-table .ant-table-pagination-right .ant-pagination-total-text,
.flat-table .innerTable .ant-pagination-total-text {
  position: absolute;
  left: 100px;
  bottom: 10px;
  height: 20px;
  line-height: 26px;
  color: #8892a5;
  font-size: 12px;
}

.flat-table .ant-table-pagination-right .ant-pagination-options,
.flat-table .innerTable .ant-pagination-options {
  background-color: #e9e9f8;
  position: absolute;
  left: 0;
  bottom: 0px;
  margin-left: 5px;
  border-radius: 7px;
}
.flat-table .ant-pagination.innerTable .ant-pagination-options {
  bottom: -6px;
}
.flat-table .ant-pagination.innerTable .ant-pagination-total-text {
  bottom: 3px;
}
.flat-table .ant-table-pagination-right .ant-pagination-options .ant-select-selector,
.flat-table .innerTable .ant-pagination-options .ant-select-selector {
  background-color: #e9e9f8;
  border-radius: 7px;
  border: 0;
  height: 35px;
}
.flat-table .ant-table-pagination-right .ant-pagination-options .ant-select-selector .ant-select-selection-item,
.flat-table .innerTable .ant-pagination-options .ant-select-selector .ant-select-selection-item {
  font-size: 10px;
  color: #4e2c90;
  font-weight: 500;
  line-height: 35px;
}

.flat-table .ant-pagination-item-link,
.input-with-label-in-textbox.ant-input-affix-wrapper-focused, .input-with-label-in-textbox.ant-input-affix-wrapper:focus {
  background-color: transparent !important;
  border: 0;
}
.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
  z-index: 0 !important;
}

.ant-pagination-item.ant-pagination-item-active a {
  color: #4e2c90;
  font-weight: 600;
}

.ant-pagination-item a {
  color: #8892a5;
  font-size: 12px;
}

.ant-pagination-item {
  background: transparent;
  border: 0;
  min-width: 25px;
  min-height: 25px;
  height: 25px;
  line-height: 25px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  min-width: 25px;
  min-height: 25px;
  height: 25px;
  line-height: 25px;
}

/* flat table css end */

/* sidepanel css */

.sidepanel-section .panel-header,
.sidepanel-section .panel-content {
  padding: 30px 30px 0 30px;
}

.sidepanel-section .ant-drawer-mask {
  background-color: #000000b3;
}
/* sidepanel css ends */

.custom-select-dropdown.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  border-radius: 13px;
  background-color: #f3f5f8;
  border: 1px solid #f3f5f8;
  height: 25px;
  display: flex;
  align-items: center;
}
.custom-select-dropdown.ant-select-multiple .ant-select-selection-item-remove,
.anticon.anticon-close.ant-tag-close-icon {
  width: 20px;
  height: 20px;
  background-color: #d95128;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  justify-content: center;
}
.no-pad-panel-content .panel-content {
  padding-left: 0;
  padding-right: 0;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.roleCheckBox .ant-checkbox-group {
  width: 100%;
  padding: 0 4px 0 10px;
}
.roleCheckBox .ant-checkbox-wrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.roleCheckBox label.ant-checkbox-wrapper .ant-checkbox + span {
  margin-right: auto;
  color: #383a65;
  font-weight: 400;
  font-size: 13px;
  padding-left: 0;
  word-break: break-word;
}
.custom-label .ant-checkbox + span,
.custom-label span.ant-radio + * {
  color: #8892a5;
  font-weight: 500;
  font-size: 12px;
}
.custom-collapse .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid transparent;
}
.custom-collapse .ant-collapse-content.ant-collapse-content-active {
  background-color: #f7f8fa;
  margin: 5px 10px 10px;
}
.custom-collapse .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 35px;
  padding-right: 55px;
}
.custom-collapse .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 26px;
}
.custom-collapse .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 16px;
}

.step-custom-tab .ant-tabs-nav {
  background-color: #e8ebf0;
}
.step-custom-tab.custom-tab .ant-tabs-nav-wrap {
  padding: 0 !important;
}
.step-custom-tab .ant-tabs-tab {
  padding: 12px 20px;
}
.step-custom-tab .ant-tabs-tab.ant-tabs-tab-active,
.step-custom-tab.custom-tab.allTabSelected .ant-tabs-nav {
  background-color: #8892a5;
}
.step-custom-tab.custom-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.step-custom-tab.custom-tab.allTabSelected .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  color: #fff;
}
.step-custom-tab.custom-tab .ant-tabs-ink-bar {
  background: transparent;
}
.step-custom-tab.custom-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.ant-popover-inner {
  border-radius: 7px;
  box-shadow: 0 4px 14px #0000001a;
}
.ant-popover-inner-content {
  padding: 10px 12px;
}

/* toast message css */

.ant-message {
  bottom: 41px;
  top: inherit;
}

.ant-message-notice .ant-message-notice-content {
  color: #fff;
  border-radius: 8px;
  width: 70%;
  text-align: left;
  padding: 15px 20px;
}

.ant-message-notice span.anticon {
  display: none;
}

.success-toast-msg .ant-message-notice-content {
  background-color: #4e2c90;
}

.error-toast-msg .ant-message-notice-content {
  background-color: #d95128;
}

/* toast message css ends */

::-webkit-file-upload-button,
.custom-address-dropdown .css-yk16xz-control {
  cursor: pointer;
}
.custom-address-dropdown {
  z-index: 3;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  display: block;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: rgba(255, 255, 255, 0.4);
}
.ant-spin-blur {
  opacity: 1;
}
.ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.lockimage {
  filter: grayscale(1) brightness(2.5);
}
.lockimage:hover {
  filter: none;
}

/* custome modal */
.custom-model-content {
  max-height: 350px;
  overflow-y: auto;
}

.custom-model-content::-webkit-scrollbar {
  width: 4px;
}
/* Track */
.custom-model-content::-webkit-scrollbar-track {
  background: #edf0f2;
  border-radius: 5px;
}

/* Handle */
.custom-model-content::-webkit-scrollbar-thumb {
  background: #c8c2bd;
  border-radius: 5px;
}

/* Handle on hover */
.custom-model-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custom-model-content pre {
  white-space: pre-wrap;
  font-family: "Source Sans Pro", sans-serif;
}
.modal-cancel-btn:hover,
.modal-cancel-btn:active,
.modal-cancel-btn:focus {
  background-color: #ffffff !important;
  background: #ffffff !important;
  color: #8892a5 !important;
  border-color: #e0e0e3 !important;
}
.modal-confirm-button:hover,
.modal-confirm-button:active,
.modal-confirm-button:focus {
  background-color: #4e2c90 !important;
  background: #4e2c90 !important;
  color: #ffffff !important;
  border-color: #4e2c90 !important;
}
.custom-select-dropdown.custom-placeholder.alert-filter-open-time-dropdown .ant-select-selector {
  height: 100%;
  padding: 3px 11px;
}
.custom-select-dropdown.custom-placeholder.alert-filter-open-time-dropdown .ant-select-arrow {
  margin-top: -4px;
}
.custom-select-dropdown.custom-placeholder.alert-filter-open-time-dropdown.with-custom-close-icon .ant-select-arrow {
  width: 24px;
  height: 24px;
  bottom: 10px !important;
  top: inherit;
  margin-top: 0 !important;
}
.custom-select-dropdown-with-checkbox .ant-select-item-option-state {
  border: 1px solid #e0e0e3;
  background-color: #e9e9f8;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
}
.custom-select-dropdown-with-checkbox .ant-select-item-option-content .options-checkbox {
  display: inline-block !important;
}
.custom-select-dropdown-with-checkbox .ant-select-item-option-state .anticon svg {
  color: #4e2c90;
  font-size: 10px;
}
.custom-select-dropdown-with-checkbox.ant-select-item-option-selected
  .ant-select-item-option-content
  .options-checkbox {
  display: none !important;
}
.custom-select-dropdown-with-checkbox.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}
.custom-select-dropdown-option-inside-search .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-radius: 7px !important;
  max-width: calc(100% - 24px) !important;
  height: 35px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e3 !important;
  margin: 5px 12px 8px;
}
.custom-select-dropdown-option-inside-search .ant-input-affix-wrapper.ant-input-affix-wrapper-lg .ant-input-prefix img {
  margin-left: 0 !important;
}
.custom-select-dropdown-option-inside-search .ant-input-affix-wrapper.ant-input-affix-wrapper-lg .ant-input {
  font-size: 12px;
  font-weight: 500;
  color: #8892a5;
}
.ant-checkbox-inner,
.ant-radio-checked .ant-radio-inner,
.ant-radio-inner {
  border-color: #e0e0e3;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e9e9f8;
  border-color: #e9e9f8;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #4e2c90;
  height: 8px;
  width: 4px;
}
.ant-radio-inner::after {
  background-color: #4e2c8f;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox:hover {
  border-color: #4e2c8f;
}

.details-btn {
  background-color: transparent;
  color: #4E2C90;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--secondary-font);
  font-weight: 600;
  padding:1.318vw 1.098vw;
  box-shadow: 0px 3px 6px #00000012;
}
.session-link:hover {
  color: #4E2C90;
}
.ant-tooltip.custom-scrollbar .ant-tooltip-inner {
  overflow-x: hidden;
}
.single-select-dropdown .ant-select-selection-item ,
.ant-select-single.single-select-dropdown .ant-select-selector .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.single-select-dropdown .ant-select-selection-item div {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  max-width: 240px;
}
.sidepanel_filter .single-select-dropdown .ant-select-selection-item {
  font-weight: 500;
}
.single-select-dropdown.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgb(136, 146, 165);
}

textarea:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.react-image-dot__wrapper {
  background-size: cover;
  position: relative;
  background-color: gray;
}

.react-image-dot {
  background-color: red;
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  transform: translate(-5px, -5px);
  cursor: move; /* Fallback for older browsers */
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.react-image-dot__grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.react-image-dot__wrapper_add > div img {
  height: 100% !important;
}

.react-image-dot__wrapper_add > div {
  background-size: cover;
  position: relative;
  background-color: gray;
}
.flat-table-white-bg.flat-table table,
.flat-table-white-bg.flat-table table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #ffffff;
}
.flat-table-white-bg.flat-table table tr td{
  border-bottom: 0;
}
.flat-table-white-bg.column-direction-righttoleft.flat-table table {
  direction: rtl;
}
.flat-table-white-bg.column-direction-righttoleft.flat-table table tr td,
.flat-table-white-bg.column-direction-righttoleft.flat-table table tr th {
  padding: 8px 16px 8px 16px; 
}

.flat-table.column-direction-righttoleft .ant-table-thead tr th:first-child, .flat-table.column-direction-righttoleft table tr td:first-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.flat-table.column-direction-righttoleft .ant-table-thead tr th:last-child, 
.flat-table.column-direction-righttoleft table tr td:last-child,
.flat-table-direction-rtl-with-scroll.flat-table.column-direction-righttoleft .ant-table-thead tr th:nth-last-child(2) {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.flat-table-direction-rtl-with-scroll .ant-table-container table > thead > tr:first-child th:last-child {
  display: none;
}
.flat-table.flat-table-direction-rtl-with-scroll {
  margin-left: -4px;
  max-width: 103%;
}
.flat-table.flat-table-direction-rtl-with-scroll table tr td:first-child{
  padding-right: 11px !important;
}
.flat-table-direction-rtl-with-scroll .ant-table-thead tr th:nth-last-child(2),
.flat-table-direction-rtl-with-scroll .ant-table-thead tr th:nth-last-child(3) {
  padding-left: 12px !important;
}
.flat-table-direction-rtl-with-scroll .ant-table-thead tr th:nth-last-child(4) {
  padding-left: 0 !important;
}
.ant-drawer.ant-drawer-open pre {
  color: #383A65 !important;
}
.expandable-dynamic-table .ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 52px;
}
.expandable-with-status-icon.expandable-dynamic-table .ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 50px;
}
.expandable-with-status-icon.flat-table .ant-table-thead .ant-table-cell:nth-child(2) span {
  display: none;
}
.status-badge {
  position: absolute;
  left: 0;
  width: 6px;
  background-color: red;
  content: "";
  height: 100%;
  top: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.status-badge.Modified{
  background-color: #FFBA38;
}
.status-badge.Removed {
  background-color: #FF7850;
}
.status-badge.Created {
  background-color: #83D49D;
}

.modified-text,
.logout-text,
.invalidsession-text,
.proccessed-text {
  color: #ffc140 !important;
}
.error-text {
  color: #FF7850 !important;
}
.success-text,
.assigned-text,
.logon-text,
.active-text,
.sent-text {
  color: #7ed99c !important;
}
.userlocked-text,
.removed-text,
.timeout-text,
.inactive-text,
.pending-text {
  color: #babbbf !important;
}
.imported-text {
  color: #e2b4f5 !important;
}
.created-text {
  color: #428bfa !important;
}
.unknown-text {
  color: #e7f5c2 !important;
}
.custom-required-placeholder input.ant-input.input-with-label-in-textbox::placeholder,
.custom-required-placeholder .ant-select-selection-placeholder, 
.input-div.custom-required-placeholder textarea::placeholder{
  padding-left: 10px;
}
.custom-required-placeholder .ant-select-selection-placeholder::before,
.input-div.custom-required-placeholder::before {
  position: absolute;
  content: "*";
  left: 0;
  width: 14px;
  height: 14px;
  color: #FF7850;
}
.input-div.custom-required-placeholder::before {
  top: 10px;
  left: 13px;
  z-index: 1;
}
.custom-select-static-dropdown .ant-select-selection-placeholder{
  position: relative;
  padding-left: 10px !important;
}
.custom-select-static-dropdown .ant-select-selection-placeholder::before{
  top: 0;
}

.custom-select-dropdown.ant-select-multiple.ant-select-lg .ant-select-selection-item .select-radio-dropdown {
  display: flex !important;
}
input[type='radio']:checked:before {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  top: 48%;
  left: 48%;
  transform: translate(-48%, -48%);
  position: absolute;
  background-color: rgb(78, 44, 144);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}
input[type='radio']:checked:after {
  border: 1px solid rgb(78, 44, 144);
  width: 13px;
  height: 13px;
  border-radius: 15px;
  position: relative;
  content: "";
  display: block;
}

.icon-btn .ant-btn {
  color: rgba(0, 0, 0, 0.65);
}

.icon-btn .ant-btn:hover, .icon-btn .ant-btn:focus {
  color: #67499c;
  background: #fff;
  border-color: #67499c;
}
#app-version {
  position: absolute;
  bottom: 10px;
  right: 10px;
}