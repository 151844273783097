/* drawer styling ant */
.ant-drawer-content-wrapper .ant-drawer-content {
    border-radius: 24px 0px 0px 24px;
}

.sidebar-menu-drawer .ant-drawer-content-wrapper .ant-drawer-content {
    border-radius: 0px !important;
    mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera */
}
.ant-drawer-body::-webkit-scrollbar-thumb {
    background: #e0e0e3 !important;
    border-radius: 10px !important;
    min-height: 44px !important;
}
.ant-drawer-body {
    overflow: hidden;
}
.mini-menu-text.mini-menu-icon-hover:hover {
    background-color: rgb(121, 107, 169) !important;
}
.mini-menu-text .menu-label {
    opacity: 0;
    width: 0;
    height: 0;
    transition: 500ms opacity, 1ms left;
    left: 0;
    overflow: hidden;
}
.submenu-mini-menu-text.menu-label {
    height: auto !important;
}
.menu-label a:hover,
a:focus {
    color: rgba(255, 255, 255, 0.8);
}
.sidebar-open-link-bg:hover,
.sidebar-open-submenu-link-bg:hover,
.minimenu-submenu-bg:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
}
.ant-drawer-body::-webkit-scrollbar {
    width: 0 !important;
}